import React from 'react';
import classNames from 'classnames';
import { Typography } from '@sravni/react-design-system';
import styles from './heading.module.scss';

type Props = Typography.HeadingProps;

export const Heading: React.FC<Props> = (props) => {
    const { className, ...other } = props;
    return <Typography.Heading className={classNames(className, styles.root)} {...other} />;
};
