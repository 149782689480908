import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Badge } from '@sravni/react-design-system';
import cn from 'classnames';
import { Paper } from '../ui';
import { Heading } from '../heading';
import styles from './styles.module.scss';

const defaultStepsImages = [
    require('./assets/step1.svg'),
    require('./assets/step2.svg'),
    require('./assets/step3.svg'),
    require('./assets/step4.svg'),
];

export interface HowToStep {
    title: string;
    description: string;
    imgUrl?: string;
}

type Props = ComponentPropsWithoutRef<'div'> & {
    steps: HowToStep[];
};

export const StepsHowTo: FC<Props> = (props) => {
    const { className, steps } = props;
    return (
        <div className={cn(styles.cardsContainer, className)}>
            {steps.map((step, i) => (
                <Paper className={styles.card} key={i}>
                    <Badge className={styles.badge} variant="primary" text={`ШАГ ${i + 1}`} />
                    <Heading level={5}>{step.title}</Heading>
                    <p>{step.description}</p>
                    <img alt="" src={step.imgUrl || defaultStepsImages[i]} />
                </Paper>
            ))}
        </div>
    );
};
