import React from 'react';
import cn from 'classnames';
import styles from './heading.module.scss';

type Props = React.ComponentProps<'div'>;

export const SubTitle: React.FC<Props> = ({ children, className, ...others }) => {
    return (
        <div className={cn(styles.subTitle, className)} {...others}>
            {children}
        </div>
    );
};
